import React from 'react';
import classnames from 'classnames';

import style from './style.module.scss';

const CustomTooltip = ({
  active,
  payload,
  label,
  isPercentage,
  isGradient,
}) => {
  if (active) {
    return (
      <div className={style.ChartTooltip}>
        <span className={style.tooltipTitle}>{`${label}`}</span>
        {payload.map((item) => (
          <div className={style.tooltipValueContainer}>
            <span
              className={classnames(style.dot, { [style.gradient]: isGradient })}
              style={{ backgroundColor: item.stroke || item.color || item.fill }}
            />
            <span
              className={style.tooltipValue}
              style={{ color: item.stroke || item.color || item.fill }}
            >
              {`${isPercentage ? `${item.value.toFixed(2)}%` : item.value} ${item.dataKey}`}
            </span>
          </div>
        ))}
      </div>
    );
  }

  return null;
};

export default CustomTooltip;
