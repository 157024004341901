import React from 'react';
import { Card, OverlayTrigger } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUp, faArrowDown, faChevronRight } from '@fortawesome/pro-regular-svg-icons';
import classnames from 'classnames';

import Info from '../../../../components/Info';

import style from './style.module.scss';

const OverlayTriggerWrapper = ({ children, overlay }) => {
  if (overlay) {
    return (
      <OverlayTrigger rootClose overlay={overlay} placement="left" trigger="click">
        {children}
      </OverlayTrigger>
    );
  }

  return children;
};

const OverviewContainer = ({
  title,
  description,
  value,
  icon,
  variant,
  average,
  children,
}) => {
  const differenceAverage = (value / average - 1) * 100;
  const styleClass = differenceAverage >= 0 ? style.green : style.red;

  return (
    <Card className={style.OverviewContainer}>
      <FontAwesomeIcon className={classnames(style.Icon, style[variant])} icon={icon} />
      <OverlayTriggerWrapper overlay={children}>
        <div
          className="d-flex flex-grow-1 align-items-center justify-content-between ml-3"
          style={{ cursor: children ? 'pointer' : 'inherit' }}
        >
          <div className={style.DataContainer}>
            <div className="d-flex">
              <span className={style.DataValue}>{value}</span>
              {!Number.isNaN(differenceAverage) && (
                <div className={classnames(style.PercentageContainer, styleClass)}>
                  <div className={classnames(style.IconContainer, styleClass)}>
                    <FontAwesomeIcon
                      className={style.PercentageIcon}
                      icon={differenceAverage >= 0 ? faArrowUp : faArrowDown}
                    />
                  </div>
                  <span className={style.DataPercentage}>{`${differenceAverage.toFixed(0)}%`}</span>
                </div>
              )}
            </div>
            <span className={style.DataTitle}>{title}</span>
          </div>
          {children && <FontAwesomeIcon icon={faChevronRight} />}
        </div>
      </OverlayTriggerWrapper>
      {description && (
        <div className={style.InfoTrigger}>
          <Info content={description} position="bottom" />
        </div>
      )}
    </Card>
  );
};

export default OverviewContainer;
