import React from 'react';
import { connect } from 'react-redux';
import { isEmpty } from 'lodash';

import ChartCard from '../../ChartComponents/ChartCard';
import BarChart from '../../ChartComponents/BarChart';
import Info from '../../../../components/Info';

import { getPercentage } from '../../utils';

const GenderChart = ({ gender }) => {
  const totalAmmount = Object.values(gender).reduce((prev, curr) => (prev + curr), 0);

  const chartData = Object.entries(gender).map(([key, value]) => ({
    name: key,
    total: value,
    percentage: getPercentage(value, totalAmmount),
  }));

  if (!isEmpty(chartData)) {
    return (
      <ChartCard
        title={(
          <Info label="Gender" content="The number of users of each gender based on Brizicam image data" className="ml-2" />
        )}
        isGradient
      >
        <BarChart
          barColor="#3373E4"
          data={chartData}
          xAxisKey="name"
          barKey="total"
          labelKey="percentage"
          hideYAxis
          renderLabel
          isGradient
        />
      </ChartCard>
    );
  }

  return null;
};

export default connect(
  ({ analytics: { briziVision: { data: { gender } } } }) => ({ gender }),
)(GenderChart);
