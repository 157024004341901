import React from 'react';
import { Card } from 'react-bootstrap';
import EmailVerification from './EmailVerification';

import backgroundImage from '../../assets/images/login.png';
import briziLogo from '../../assets/images/briziLogo.png';
import '../../vendor/styles/pages/authentication.scss';
import ChangePassword from './ChangePassword';

import style from '../Login/style.module.scss';

const PasswordReset = ({ match: { params: { token } } }) => (
  <div className={style.loginContainer} style={{ backgroundImage: `url(${backgroundImage})` }}>
    <img className={style.logo} src={briziLogo} alt="background-arena" />
    <Card className={style.formContainer}>
      <div>
        {token ? <ChangePassword token={token} /> : <EmailVerification /> }
      </div>
    </Card>
  </div>
);

export default PasswordReset;
