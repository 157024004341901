import React from 'react';
import { connect } from 'react-redux';
import { faTrash, faDownload, faUpload } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { removeOrigin } from '../../../../../helpers/urls';
import { deleteImage } from '../../actions';

import RoundButton from '../../../../../components/RoundButton';

import style from './style.module.scss';

const ActionButtons = ({
  data,
  imageKey,
  selectFile,
  deleteImageAction,
}) => {
  const isEmpty = Object.values(data).every((val) => !val);

  if (isEmpty) {
    return null;
  }

  return (
    <div className="ml-3 d-flex">
      <RoundButton title="Upload" className={style.actionBtn} onClick={selectFile}>
        <FontAwesomeIcon icon={faUpload} className="align-middle" />
      </RoundButton>
      <a
        className={style.actionBtn}
        title="Download"
        href={removeOrigin(data[`${imageKey}RawUrl`] || data[`${imageKey}Url`])}
        download
        target="_blank"
        rel="noopener noreferrer"
      >
        <FontAwesomeIcon icon={faDownload} className="align-middle" />
      </a>
      <RoundButton title="Delete" className={style.actionBtn} onClick={deleteImageAction}>
        <FontAwesomeIcon icon={faTrash} className="align-middle" />
      </RoundButton>
    </div>
  );
};

export default connect(
  ({ graphics: { sections, selectedInd } }) => ({
    data: sections[selectedInd].data,
    imageKey: sections[selectedInd].key,
  }),
  { deleteImageAction: deleteImage },
)(ActionButtons);
