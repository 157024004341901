import React, { useEffect, useState } from 'react';
import * as Recharts from 'recharts';
import { connect } from 'react-redux';
import { isEmpty } from 'lodash';

import ChartCard from '../../ChartComponents/ChartCard';
import ChartTooltip from '../../ChartComponents/ChartTooltip';
import Info from '../../../../components/Info';

import { formatDateToTimezone } from '../../../../helpers/utils';

const GroupMemoryChart = ({ groupMemoryData, timezone }) => {
  const [chartData, setChartData] = useState([]);
  const [showUsers, setShowUsers] = useState(true);
  const [showSnapshots, setShowSnapshots] = useState(true);

  useEffect(() => {
    if (!isEmpty(groupMemoryData)) {
      setChartData(groupMemoryData.map(({ date, users: photographers, snapshots: photos }) => ({
        photographers,
        photos,
        time: formatDateToTimezone(date, timezone, 'hh:mm a'),
      })));
    }
  }, [groupMemoryData]);

  const handleLegendClick = (series) => {
    if (series === 'photos') {
      setShowSnapshots(!showSnapshots);
      return;
    }
    setShowUsers(!showUsers);
  };

  if (!isEmpty(chartData)) {
    return (
      <ChartCard title={(
        <Info
          label="Group Memory Timeline"
          content="A visualization of the number of photographers, photos, and shares during the event"
          className="ml-2"
        />
      )}
      >
        <Recharts.ResponsiveContainer width="100%" height={350}>
          <Recharts.LineChart data={chartData} isAnimationActive={false}>
            <Recharts.XAxis
              dataKey="time"
              tickLine={false}
              dy={10}
              dx={5}
              tick={{
                fontSize: '12px',
                color: '#B7B8BA',
                marginRight: '10px',
                lineHeight: '27px',
                fontWeigth: '500',
              }}
            />
            <Recharts.YAxis
              tickLine={false}
              dy={-5}
              dx={-5}
              tick={{
                fontSize: '12px',
                color: '#B7B8BA',
                marginRight: '10px',
              }}
            />
            <Recharts.Tooltip content={<ChartTooltip />} />
            <Recharts.Legend
              verticalAlign="top"
              align="right"
              wrapperStyle={{ fontSize: '14px', color: '#4E5155', textTransform: 'capitalize' }}
              iconType="circle"
              iconSize="11"
              onClick={({ dataKey }) => handleLegendClick(dataKey)}
            />
            <Recharts.Line type="monotone" dataKey="photos" stroke="#BB6BD9" fill="#BB6BD9" dot={false} strokeWidth={2} hide={!showSnapshots} />
            <Recharts.Line type="monotone" dataKey="photographers" stroke="#F2568E" fill="#F2568E" dot={false} strokeWidth={2} hide={!showUsers} />
          </Recharts.LineChart>
        </Recharts.ResponsiveContainer>
      </ChartCard>
    );
  }

  return null;
};

export default connect(
  ({
    events: { currentEvent: { timezone } },
    analytics: { basic: { data: { usersVsSnapshotsTimelines } } },
  }) => ({
    timezone,
    groupMemoryData: usersVsSnapshotsTimelines,
  }),
)(GroupMemoryChart);
