import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
} from 'react';
import classNames from 'classnames';
import { isEmpty } from 'lodash';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Modal, Button, Spinner } from 'react-bootstrap';

import { closeModal } from './actions';
import { setSelectedEvents, resetSelectedEvents } from '../../../store/actions/events';
import { usePage } from '../../../helpers/hooks';

import CheckIcon from './CheckIcon';

import style from './style.module.scss';

const mapEvents = (allEvents, selectedEvents, showFutureEvents) => {
  let events = allEvents;

  if (!showFutureEvents) {
    events = events.filter((event) => !event.isFutureEvent);
  }

  return events.map((event) => {
    const { _id } = event;
    const [day, date] = event.gameDateStr.split(' ');
    const time = `${event.startTime} - ${event.endTime}`;

    return {
      _id,
      day,
      date,
      isSelected: !!selectedEvents.find(({ _id: selectedId }) => selectedId === _id),
      title: event.title,
      // TODO remove Invalid date check after moving to new admin
      time: time !== 'Invalid date - Invalid date' ? time : '',
    };
  });
};

function isBetween(x, min, max) {
  return x >= Math.min(min, max) && x <= Math.max(min, max);
}

const EventSelector = (props) => {
  const {
    isOpen,
    isFetching,
    error,
    allEvents,
    template,
    currentEvent,
    selectedEvents,
    selectedEventsPage,
    shiftKey,
    singleSelection,
    showTemplate,
    showFutureEvents,
    closeModalAction,
    setSelectedEventsAction,
    resetSelectedEventsAction,
  } = props;

  const [events, setEvents] = useState([]);
  const lastSelected = useRef();
  const page = usePage();
  const { eventId } = useParams();

  useEffect(() => {
    // NOTE: it will select event whenever eventId param is present in any route
    // that has this component
    if (eventId) {
      const event = allEvents.find(({ _id }) => _id === eventId);
      if (event && (selectedEvents.length !== 1 || event._id !== selectedEvents[0]._id)) {
        setSelectedEventsAction([event], page);
        return;
      }

      if (eventId === template._id && !selectedEvents.length) {
        setSelectedEventsAction([template], page);
        return;
      }
    }

    if (selectedEventsPage !== page) {
      resetSelectedEventsAction(page);
    } else if (selectedEvents.length === 0 && allEvents.length > 0 && !!currentEvent) {
      setSelectedEventsAction([currentEvent], page);
    }
  }, [selectedEventsPage, allEvents, selectedEvents, currentEvent]);

  useEffect(() => {
    if (isOpen && !error) {
      setEvents(mapEvents(allEvents, selectedEvents, showFutureEvents));
      lastSelected.current = !isEmpty(selectedEvents) && selectedEvents[0]._id;
    }
  }, [isOpen, error, allEvents, selectedEvents]);

  const selectTemplate = () => {
    setSelectedEventsAction([template], page);
    closeModalAction();
  };

  const singleEventSelection = ({ _id }) => {
    setEvents(events.map((event) => ({ ...event, isSelected: event._id === _id })));
  };

  const toggleEvents = (start, end, isSelected) => {
    setEvents(events.map((event, index) => {
      if (isBetween(index, start, end)) {
        return { ...event, isSelected };
      }
      return event;
    }));
  };

  const toggleEvent = ({ _id, isSelected }) => {
    setEvents(events.map(
      (event) => (event._id === _id ? { ...event, isSelected: !isSelected } : event),
    ));
  };

  const toggleHandler = (event) => () => {
    const { _id, isSelected } = event;
    const intervalUpdate = shiftKey && lastSelected.current;

    if (singleSelection) {
      singleEventSelection(event);
    } else if (intervalUpdate) {
      const start = events.findIndex(({ _id: id }) => id === lastSelected.current);
      const end = events.findIndex(({ _id: id }) => id === _id);
      toggleEvents(start, end, !isSelected);
    } else {
      toggleEvent(event);
    }

    lastSelected.current = _id;
  };

  const someNotSelected = events.some(({ isSelected }) => !isSelected);

  const selectAll = () => {
    const selected = events.length && someNotSelected;
    setEvents(events.map((event) => ({ ...event, isSelected: selected })));
  };

  const getBody = () => {
    if (error) {
      return (
        <div className={style.staticContent}>
          <p>Failed to fetch events.</p>
        </div>
      );
    }

    if (isFetching) {
      return (
        <div className={style.staticContent}>
          <Spinner as="span" animation="border" aria-hidden="true" />
        </div>
      );
    }

    if (events.length === 0) {
      return <p className={style.emptyModalText}>There are no events.</p>;
    }

    return (
      <table>
        <thead>
          <tr>
            <th>
              {!singleSelection && (
                <CheckIcon
                  inverted
                  onClick={selectAll}
                  checked={!someNotSelected}
                />
              )}
            </th>
            <th>Name</th>
            <th>Date</th>
            <th>Time</th>
            <th>
              {!isEmpty(selectedEvents) && (
                <Button onClick={closeModalAction}>
                  <i className="d-block ion ion-md-close" />
                </Button>
              )}
            </th>
          </tr>
        </thead>
        <tbody>
          {events.map((event) => (
            <tr
              key={event._id}
              onClick={toggleHandler(event)}
              className={classNames({ [style.selected]: event.isSelected })}
            >
              <td>
                <CheckIcon checked={event.isSelected} />
              </td>
              <td className="text-uppercase">{event.title}</td>
              <td>{event.date}</td>
              <td>{event.time}</td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  };

  const onSaveHandler = useCallback(() => {
    const selected = events.filter(({ isSelected }) => isSelected);
    setSelectedEventsAction(selected, page);
    closeModalAction();
  }, [events]);

  const isBtnDisabled = !events.some(({ isSelected }) => isSelected);

  return (
    <Modal
      show={isOpen}
      onHide={closeModalAction}
      className={style.eventSelectorContainer}
      backdrop={isEmpty(selectedEvents) ? 'static' : true}
    >
      <Modal.Body className={style.modalBody}>
        {getBody()}
      </Modal.Body>
      <Modal.Footer>
        {showTemplate && (
          <Button className="mr-auto" variant="primary" onClick={selectTemplate}>
            Master Template
          </Button>
        )}
        <Button variant="primary" onClick={onSaveHandler} disabled={isBtnDisabled}>
          Apply
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default connect(({
  modals: {
    eventSelector: {
      isOpen,
    },
  },
  events: {
    isFetching,
    error,
    allEvents,
    template,
    currentEvent,
    selectedEvents,
    selectedEventsPage,
  },
  keys: { shiftKey },
}) => ({
  isOpen,
  isFetching,
  error,
  allEvents,
  template,
  currentEvent,
  selectedEvents,
  selectedEventsPage,
  shiftKey,
}), {
  closeModalAction: closeModal,
  setSelectedEventsAction: setSelectedEvents,
  resetSelectedEventsAction: resetSelectedEvents,
})(EventSelector);
