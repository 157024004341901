import React, { forwardRef } from 'react';
import classnames from 'classnames';

import style from './style.module.scss';

const RoundButton = forwardRef(({
  onClick,
  className,
  children,
  title,
}, ref) => (
  <button ref={ref} title={title} type="button" onClick={onClick} className={classnames(style.btn, className)}>
    <span>
      {children}
    </span>
  </button>
));

export default RoundButton;
