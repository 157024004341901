import React from 'react';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';

const Table = ({
  rowKey,
  data,
  columns,
  selectRow,
  onRowClick,
  pagination,
}) => (
  <ToolkitProvider
    keyField={rowKey}
    data={data}
    columns={columns}
    bootstrap4
  >
    {({ baseProps }) => (
      <BootstrapTable
        {...baseProps}
        wrapperClasses="table-responsive react-bs-table-card"
        classes="card-table table-striped border-top"
        pagination={pagination ? paginationFactory() : false}
        rowEvents={{ onClick: onRowClick }}
        selectRow={selectRow}
      />
    )}
  </ToolkitProvider>
);

export default Table;
