import { Permission } from '../Authorize/permissions';

export const DELETE_PHOTO = new Permission('album_snapshot_deleteBtn', 'Allow to delete snapshots');
export const BAN_PHOTO_USER = new Permission('album_snapshot_banBtn', 'Allow to ban app users');
export const SELECT_PHOTO = new Permission('album_snapshot_selectBtn', 'Allow to select multiple snapshots');

// accorditions
export const USER_INFO_ACCORDITION = new Permission('lightbox_infoSidebar_userInfo', 'Allow to view user info section in lightbox');
export const EXIF_ACCORDITION = new Permission('lightbox_infoSidebar_exif', 'Allow to view EXIF data section in lightbox');
export const BRIZI_VISION_ACCORDITION = new Permission('lightbox_infoSidebar_briziVision', 'Allow to view Brizi vision section in lightbox');
