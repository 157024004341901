import React from 'react';
import { Button } from 'react-bootstrap';

import { ReactSwal } from '../../../../helpers/swal';

export default (turnOffFn) => {
  const turnOffBtnHandler = (evt) => {
    evt.nativeEvent.stopImmediatePropagation();
    ReactSwal.close();

    turnOffFn();
  };

  const closeBtnHandler = (evt) => {
    evt.nativeEvent.stopImmediatePropagation();
    ReactSwal.close();
  };

  return ReactSwal.fire({
    title: 'Are you sure?',
    type: 'warning',
    html: (
      <div>
        <p>
          We recommend you to change event status to &apos;Pre&apos; or &apos;Post&apos;
          status before turning off camera.
          Are you sure you want to turn off the camera while the event is live?
        </p>
        <br />
        <Button
          variant="danger"
          className="swal2-confirm btn btn-danger btn-lg"
          onClick={turnOffBtnHandler}
        >
          Yes, turn off the cameras
        </Button>
        &nbsp;
        <Button
          className="swal2-confirm btn btn-danger btn-lg"
          variant="default"
          onClick={closeBtnHandler}
          autoFocus
        >
          Cancel
        </Button>
      </div>
    ),
  });
};
