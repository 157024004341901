import React from 'react';
import { connect } from 'react-redux';
import { isEmpty } from 'lodash';
import ChartCard from '../../ChartComponents/ChartCard';
import BarChart from '../../ChartComponents/BarChart';
import Info from '../../../../components/Info';

const AgeChart = ({ ageRange }) => {
  if (isEmpty(ageRange)) {
    return null;
  }

  const chartData = Object.entries(ageRange).map(([key, value]) => ({
    name: key,
    percentage: value,
  }));

  return (
    <ChartCard
      title={<Info label="Age" content="The number of users in each age group based on Brizcam image data" className="ml-2" />}
      isGradient
    >
      <BarChart
        barColor="#3373E4"
        data={chartData}
        xAxisKey="name"
        barKey="percentage"
        labelKey="percentage"
        hideYAxis
        renderLabel
        isPercentage
        isGradient
      />
    </ChartCard>
  );
};

export default connect(
  ({ analytics: { briziVision: { data: { ageRange } } } }) => ({ ageRange }),
)(AgeChart);
