import React, {
  useEffect,
  useMemo,
  useCallback,
  useState,
  useRef,
} from 'react';
import { connect } from 'react-redux';
import {
  Spinner,
  ToggleButton,
  ToggleButtonGroup,
  FormFile,
  Button,
  Card,
  Accordion,
} from 'react-bootstrap';
import classnames from 'classnames';
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import filterFactory, { textFilter, selectFilter } from 'react-bootstrap-table2-filter';
import cellEditFactory, { Type } from 'react-bootstrap-table2-editor';
import LaddaButton from 'react-ladda';
import Papa from 'papaparse';

import {
  fetchTriviaQuestions,
  setLanguage,
  updateQuestion,
  deleteQuestions,
  toggleSelection,
  toggleSelectionAll,
  openNewQuestionModal,
  save,
  setQuestions,
} from './actions';
import { displayErrorModal } from '../../../helpers/swal';
import { useIsLoaded } from '../../../helpers/hooks';
import { buildURL } from '../../../helpers/urls';
import { useUpdateEvent } from '../hooks';
import { headerSelection, rowSelection } from '../../../components/ReactBootstrapTable2/selection';

import Loader from '../../../shared/Loader';
import AddQuestionModal from './AddQuestionModal';
import EventSelector from '../../../components/EventSelector/Modal';
import EventSelectorButton from '../../../components/EventSelector/Button';
import UpdateButton from '../UpdateButton';

import style from './style.module.scss';

const remote = { cellEdit: true };

const Trivia = ({
  isFetching,
  isDeleting,
  isSaving,
  error,
  questions,
  selectedQuestions,
  selectedLanguage,
  allEvents,
  eventId,
  triviaEnabled: triviaEnabledDefault,
  validationError,
  fetchTriviaQuestionsAction,
  setLanguageAction,
  updateQuestionAction,
  deleteQuestionsAction,
  toggleSelectionAction,
  toggleSelectionAllAction,
  openNewQuestionModalAction,
  setQuestionsAction,
  saveAction,
}) => {
  useUpdateEvent();
  const isLoaded = useIsLoaded(isFetching);
  const [triviaEnabled, setTriviaEnabled] = useState(!!triviaEnabledDefault);
  const headerCheckboxRef = useRef();
  const languages = useMemo(() => {
    const langSet = new Set();
    allEvents.forEach(({ availableLanguages }) => {
      availableLanguages.forEach((lang) => langSet.add(lang));
    });
    return Array.from(langSet);
  }, [allEvents]);

  const columns = useMemo(() => {
    const classesValidator = (cellProp) => (cell, row) => {
      const { questionId, prop } = validationError || {};
      return classnames('hover-edit-background', { error: questionId === row._id && prop === cellProp });
    };

    return [{
      text: 'Id',
      dataField: '_id',
      sort: true,
      hidden: true,
      filter: textFilter(),
    }, {
      text: 'Question',
      dataField: 'question',
      sort: true,
      filter: textFilter(),
      editor: {
        type: Type.TEXTAREA,
      },
      classes: classesValidator('question'),
    }, {
      text: 'Answer 1',
      dataField: 'answer1',
      sort: true,
      filter: textFilter(),
      classes: classesValidator('answer1'),
    }, {
      text: 'Answer 2',
      dataField: 'answer2',
      sort: true,
      filter: textFilter(),
      classes: classesValidator('answer2'),
    }, {
      text: 'Correct Answer',
      dataField: 'correctAnswer',
      sort: true,
      editor: {
        type: Type.SELECT,
        options: [
          { value: 1, label: 'Answer 1' },
          { value: 2, label: 'Answer 2' },
        ],
      },
      filter: selectFilter({
        options: {
          1: 'Answer 1',
          2: 'Answer 2',
        },
      }),
      classes: classesValidator('correctAnswer'),
    }];
  }, [validationError]);

  const selectRow = {
    selected: selectedQuestions,
    mode: 'checkbox',
    classes: 'table-success',
    onSelect: toggleSelectionAction,
    onSelectAll: toggleSelectionAllAction,
    headerColumnStyle: { verticalAlign: 'middle', textAlign: 'center', padding: '20px' },
    selectColumnStyle: { verticalAlign: 'middle', textAlign: 'center' },
    selectionRenderer: rowSelection,
    selectionHeaderRenderer: headerSelection(headerCheckboxRef),
  };

  useEffect(() => {
    if (selectedLanguage && eventId) {
      fetchTriviaQuestionsAction(selectedLanguage, eventId);
    }
  }, [selectedLanguage, eventId]);

  useEffect(() => {
    if (!languages.includes(selectedLanguage)) {
      setLanguageAction(languages[0]);
    }
  }, [allEvents]);

  useEffect(() => {
    setTriviaEnabled(!!triviaEnabledDefault);
  }, [eventId, triviaEnabledDefault]);

  useEffect(() => {
    if (selectedQuestions.length !== 0 && selectedQuestions.length !== questions.length) {
      headerCheckboxRef.current.indeterminate = true;
    }
  }, [selectedQuestions]);

  const toggleTrivia = useCallback(() => setTriviaEnabled(!triviaEnabled), [triviaEnabled]);

  const updateHandler = useCallback(
    (eventIds) => saveAction(eventIds, triviaEnabled),
    [triviaEnabled],
  );

  const onTableChange = (type, { cellEdit }) => {
    if (type === 'cellEdit') {
      updateQuestionAction(cellEdit);
      document.activeElement.blur();
    }
  };

  const importBtnHandler = async (evt) => {
    const file = evt.target.files[0];
    if (file.type !== 'text/csv') {
      displayErrorModal({ text: 'Wrong file type. Only CSV file format is supported.' });
      return;
    }

    Papa.parse(evt.target.files[0], {
      header: true,
      skipEmptyLines: true,
      complete: ({ errors, data }) => {
        if (errors.length > 0) {
          displayErrorModal({
            html: (
              <>
                <p>Errors:</p>
                {errors.map(({ message, row }) => (
                  <p key={message}>
                    {message}
                    &nbsp;&nbsp;&nbsp;&nbsp;row:&nbsp;
                    {row + 2}
                  </p>
                ))}
              </>
            ),
          });
        } else {
          setQuestionsAction(data);
        }
      },
    });
  };

  return (
    <div className={style.container}>
      <div className="pageHeader">
        <h4>Trivia</h4>
        <EventSelectorButton showFutureEvents />

        <FormFile
          as={({ children }) => (
            <Button variant="outline-primary" className={classnames(style.fileInput, 'mr-2', 'small')}>
              {children}
            </Button>
          )}
        >
          <span>Add from CSV</span>
          <FormFile.Input onChange={importBtnHandler} />
        </FormFile>

        {selectedLanguage && (
          <a
            className="btn btn-outline-primary mr-2 small"
            href={buildURL('trivia/questionsCsv', selectedLanguage)}
          >
            Export to CSV
          </a>
        )}
        <UpdateButton label="Update Event" updateHandler={updateHandler} />
      </div>

      <div className={style.content}>
        {!isLoaded && (
          <div className="d-flex flex-grow-1 justify-content-center align-items-center">
            <Spinner variant="primary" animation="border" />
          </div>
        )}

        {error && (
          <div className="d-flex flex-grow-1 justify-content-center align-items-center">
            <p>Error fetching questions.</p>
          </div>
        )}

        {isLoaded && (
          <Accordion activeKey={triviaEnabled ? '0' : null}>
            <Card>
              <Card.Header className={style.accordionHeader}>
                <h4 className="mb-0">Trivia Questions</h4>
                <Button variant="link" className="pr-0">
                  {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                  <label className="switcher labelFirst">
                    <span className="switcher-label">{triviaEnabled ? 'Enabled' : 'Disabled'}</span>
                    <input type="checkbox" checked={triviaEnabled} className="switcher-input" onChange={toggleTrivia} />
                    <span className="switcher-indicator">
                      <span className="switcher-yes" />
                      <span className="switcher-no" />
                    </span>
                  </label>
                </Button>
              </Card.Header>
              <Accordion.Collapse eventKey="0">
                <Card.Body className={style.accordionBody}>
                  {isLoaded && !error && (
                    <ToolkitProvider
                      keyField="_id"
                      data={questions}
                      columns={columns}
                      bootstrap4
                      search
                      columnToggle
                    >
                      {(props) => (
                        <div>
                          <div className={style.buttonContainer}>
                            <div>
                              <ToggleButtonGroup
                                name="language"
                                type="radio"
                                value={selectedLanguage}
                                onChange={setLanguageAction}
                              >
                                {languages.length > 1 && languages.map((langauge) => (
                                  <ToggleButton className="small" key={langauge} value={langauge}>
                                    {langauge}
                                  </ToggleButton>
                                ))}
                              </ToggleButtonGroup>
                            </div>

                            <div>
                              <Button variant="outline-primary" className="mr-2 small" onClick={openNewQuestionModalAction}>
                                Add new
                              </Button>
                              <LaddaButton
                                loading={isDeleting}
                                className="btn btn-outline-danger ml-2 small"
                                onClick={deleteQuestionsAction}
                                disabled={selectedQuestions.length === 0}
                              >
                                Delete
                              </LaddaButton>
                            </div>
                          </div>
                          <BootstrapTable
                            {...props.baseProps}
                            remote={remote}
                            wrapperClasses={classnames('table-responsive', 'searchFilter', style.table)}
                            selectRow={selectRow}
                            filter={filterFactory()}
                            cellEdit={cellEditFactory({ mode: 'click', blurToSave: true })}
                            onTableChange={onTableChange}
                            noDataIndication={() => isLoaded && <p>There are no questions.</p>}
                          />
                        </div>
                      )}
                    </ToolkitProvider>
                  )}
                </Card.Body>
              </Accordion.Collapse>
            </Card>
          </Accordion>
        )}
      </div>

      {isSaving && <Loader />}

      <EventSelector singleSelection showTemplate showFutureEvents />
      <AddQuestionModal />
    </div>
  );
};

export default connect(
  ({
    trivia: {
      questions: {
        isFetching,
        isDeleting,
        isSaving,
        error,
        selectedLanguage,
        items: questions,
        selected: selectedQuestions,
        questionUpdateCounter,
        validationError,
      },
    },
    events: {
      allEvents,
      selectedEvents,
    },
  }) => ({
    isFetching,
    isDeleting,
    isSaving,
    error,
    selectedLanguage,
    questions,
    selectedQuestions,
    allEvents,
    eventId: selectedEvents[0] && selectedEvents[0]._id,
    triviaEnabled: selectedEvents[0] && selectedEvents[0].triviaEnabled,
    questionUpdateCounter,
    validationError,
  }),
  {
    fetchTriviaQuestionsAction: fetchTriviaQuestions,
    setLanguageAction: setLanguage,
    updateQuestionAction: updateQuestion,
    deleteQuestionsAction: deleteQuestions,
    toggleSelectionAction: toggleSelection,
    toggleSelectionAllAction: toggleSelectionAll,
    openNewQuestionModalAction: openNewQuestionModal,
    setQuestionsAction: setQuestions,
    saveAction: save,
  },
)(Trivia);
