import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { Button, Form, FormCheck } from 'react-bootstrap';
import FormControl from 'react-bootstrap/FormControl';

import { displayErrorModal } from '../../../helpers/swal';
import { login } from '../../../helpers/api';
import { setUserData } from '../../../store/actions/authentication';

import style from '../style.module.scss';

const LoginForm = ({ setUserDataAction }) => {
  const [formData, setFormData] = useState({ username: '', password: '', rememberMe: false });
  const [formSubmitted, setFormSubmitted] = useState(false);

  const history = useHistory();

  const formSubmitHandler = async (event) => {
    event.preventDefault();
    event.stopPropagation();

    setFormSubmitted(true);

    if (formData.username.length && formData.password.length) {
      try {
        const userData = await login(formData);
        setUserDataAction(userData);
        history.push('/');
      } catch (err) {
        displayErrorModal({ text: 'Something went wrong, Please try again' });
        console.error(err);
      }
    }
  };

  const onChangeHandler = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.type === 'checkbox' ? e.target.checked : e.target.value,
    });
  };

  return (
    <Form noValidate onSubmit={formSubmitHandler}>
      <div className={style.topText}>
        <h4><b>Welcome Back</b></h4>
        <p>Please, login to your account</p>
      </div>
      <Form.Group>
        <Form.Control
          type="text"
          value={formData.username}
          onChange={onChangeHandler}
          name="username"
          required
          placeholder="Username"
          minLength={10}
          isInvalid={!formData.username.length && formSubmitted}
        />
        <Form.Control.Feedback type="invalid">
          Username is required
        </Form.Control.Feedback>
      </Form.Group>
      <Form.Group controlId="formPassword">
        <Form.Control
          type="password"
          value={formData.password}
          onChange={onChangeHandler}
          name="password"
          required
          placeholder="Password"
          isInvalid={!formData.password.length && formSubmitted}
        />
        <FormControl.Feedback type="invalid">
          Password is required
        </FormControl.Feedback>
      </Form.Group>
      <Form.Group className={style.checkboxWrapper}>
        <FormCheck
          custom
          type="checkbox"
          label="Remember me"
          name="rememberMe"
          checked={formData.rememberMe}
          onChange={onChangeHandler}
        />
        <div className="ml-auto">
          <Link to="/reset_password">Forgot password?</Link>
        </div>
      </Form.Group>
      <Button variant="primary" type="submit">Login</Button>
    </Form>
  );
};

export default connect(
  null,
  { setUserDataAction: setUserData },
)(LoginForm);
