import React, { useEffect, useState } from 'react';
import { Button, Spinner } from 'react-bootstrap';

import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

import '../../../vendor/libs/sweetalert2/sweetalert2.scss';
import style from './style.module.scss';

import {
  createUser,
  getUserList,
  deleteUser,
  updateUser,
} from '../../../helpers/api';

import UsersTable from './UsersTable';
import UserModal from './UserModal';

const ReactSwal = withReactContent(Swal.mixin({
  buttonsStyling: false,
  customClass: {
    confirmButton: 'btn btn-primary btn-lg',
    cancelButton: 'btn btn-default btn-lg',
    actions: 'text-center',
  },
}));

const initialState = { username: '', password: '', roles: [] };

const Users = () => {
  const [userList, setUserList] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [userData, setUserData] = useState(initialState);
  const [formMode, setFormMode] = useState('create');
  const [selectedRow, setSelectedRow] = useState();
  const [isFormLoading, setFormLoading] = useState();
  const [isListLoading, setListLoading] = useState();

  const onValueChangeHandler = (input) => {
    setUserData({
      ...userData,
      [input.name]: input.value,
    });
  };

  const onCloseHandler = () => {
    setUserData({
      ...initialState,
    });
    setShowModal(false);
  };

  const loadData = async () => {
    try {
      setListLoading(true);
      const response = await getUserList();
      const mappedList = response.map((user) => ({
        ...user,
        roles: user.roles.map((role) => ({ value: role, label: role })),
      }));

      setUserList(mappedList);
    } catch (err) {
      console.error('Error trying to load users from database', err);
    } finally {
      setListLoading(false);
    }
  };

  const deleteHandler = async (userId) => {
    await ReactSwal.fire({
      title: 'Confirm user deletion?',
      text: 'By deleting the user, he\'ll no longer be able to use the Dashboard',
      type: 'info',
      showCancelButton: true,
      showLoaderOnConfirm: true,
      allowOutsideClick: false,
      preConfirm: () => deleteUser(userId)
        .then(async () => {
          loadData();
          setSelectedRow(null);
        })
        .catch((err) => {
          console.error('Error trying to delete user from database', err);
          ReactSwal.showValidationMessage('Error trying to delete user from database. Please, try again or contact support!');
        }),
    });
  };

  const editHandler = () => {
    setFormMode('update');
    setUserData({ ...userData, ...selectedRow });
    setShowModal(true);
  };

  const createHandler = () => {
    setFormMode('create');
    setShowModal(true);
  };

  const validateAndSubmit = async () => {
    try {
      setFormLoading(true);
      const roles = (userData.roles || []).map(({ value }) => value);
      const userObject = { ...userData, roles };
      if (formMode === 'create') {
        await createUser(userObject);
      } else {
        if (!userObject.password.length) {
          delete userObject.password;
        }

        await updateUser(userObject);
      }
      loadData();
      setShowModal(false);
      setSelectedRow({ ...selectedRow, ...userData });
    } catch (err) {
      console.error(err);
      setShowModal(false);
      ReactSwal.fire({ title: 'Error creating the user' });
    } finally {
      setFormLoading(false);
    }
  };

  useEffect(() => {
    loadData();
  }, []);

  useEffect(() => {
    if (!showModal && userData !== initialState) {
      setUserData(initialState);
    }
  }, [showModal, userData]);

  return (
    <div className={style.Container}>
      <div className="pageHeader">
        <h4>Admin</h4>
        <div className="ml-auto">
          <Button
            className="mr-2 small"
            variant="outline-primary"
            onClick={editHandler}
            disabled={!selectedRow}
          >
            <i className="fas fa-pen" />
            &nbsp;
            Edit
          </Button>
          <Button
            className="mr-2 small"
            variant="outline-danger"
            onClick={() => deleteHandler(selectedRow._id)}
            disabled={!selectedRow}
          >
            Delete
          </Button>
          <Button className="small" variant="primary" onClick={createHandler}>
            Create new user
          </Button>
        </div>
      </div>
      <div className={style.Content}>
        <UsersTable
          data={userList}
          selectedRow={selectedRow}
          onRowSelect={setSelectedRow}
        />
        {isListLoading && (
          <div className="d-flex flex-grow-1 justify-content-center align-items-center">
            <Spinner variant="primary" animation="border" />
          </div>
        )}
      </div>

      <UserModal
        userData={userData}
        formMode={formMode}
        isOpen={showModal}
        isLoading={isFormLoading}
        setFormLoading={setFormLoading}
        onHide={() => setShowModal(false)}
        onValueChangeHandler={onValueChangeHandler}
        onSubmitHandler={validateAndSubmit}
        onCloseHandler={onCloseHandler}
      />
    </div>
  );
};

export default Users;
