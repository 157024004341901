import React, {
  memo,
  useState,
  useCallback,
  useEffect,
} from 'react';
import { Button } from 'react-bootstrap';
import { ChromePicker } from 'react-color';
import classnames from 'classnames';

import style from './style.module.scss';

const ColorPicker = ({
  title,
  colorName,
  value,
  onChange,
}) => {
  const [showPicker, setShowPicker] = useState(false);

  const togglePicker = useCallback((evt) => {
    if (!evt.target.closest('.chrome-picker')) {
      setShowPicker(!showPicker);
    }
  }, [showPicker]);

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (showPicker) {
      document.body.addEventListener('click', togglePicker);
      return () => {
        document.body.removeEventListener('click', togglePicker);
      };
    }

    document.body.removeEventListener('click', togglePicker);
  }, [showPicker]);

  const handlePickerChange = (pickedColor) => {
    // to be able to re-use this component with RHFInput
    // value property needs to be the first argument
    onChange(pickedColor.hex, colorName);
  };

  const handleInputChange = useCallback(({ target }) => {
    onChange(target.value.startsWith('#') ? target.value : `#${target.value}`, colorName);
  }, [onChange]);

  const rootClass = classnames(
    style.colorBlock,
    'p-3',
    'mb-2',
    'border',
    'd-flex',
    'justify-content-between',
    'align-items-center',
  );

  return (
    <div className={rootClass}>
      <span>{title}</span>
      <div>
        <span className="mr-1">Hex:</span>

        <input
          placeholder="Add hex code"
          className={style.input}
          type="text"
          value={value}
          onChange={(handleInputChange)}
        />

        <Button
          style={{ backgroundColor: value }}
          className={classnames('ml-2', 'border', style.colorBtn)}
          onClick={togglePicker}
        />

        {showPicker && (
          <ChromePicker
            disableAlpha
            className={style.colorPicker}
            color={value}
            onChange={handlePickerChange}
          />
        )}
      </div>
    </div>
  );
};

export default memo(ColorPicker);
