import React from 'react';
import {
  ResponsiveContainer,
  BarChart,
  XAxis,
  YAxis,
  Tooltip,
  Bar,
  LabelList,
} from 'recharts';

import ChartTooptip from '../ChartTooltip';

const renderCustomizedLabel = ({
  x,
  y,
  width,
  value,
  isPercentage,
  isGradient,
}) => (
  <text
    x={x + width / 2}
    y={y - 10}
    fill={isGradient ? 'url(#premiumGradient)' : '#616165'}
    textAnchor="middle"
    dominantBaseline="middle"
  >
    {isPercentage ? `${value.toFixed(2)}%` : value}
  </text>
);

const BriziBarChart = ({
  xAxisKey,
  barKey,
  labelKey,
  data,
  barColor,
  hideYAxis,
  renderLabel,
  isPercentage,
  isGradient,
}) => (
  <ResponsiveContainer height={350} width="100%">
    <BarChart
      data={data}
      margin={{ top: 50 }}
      isAnimationActive={false}
    >
      <defs>
        <linearGradient id="premiumGradient" x1="-8.15%" y1="62.7%" x2="108.15%" y2="37.3%">
          <stop offset=".124" stopColor="#3373E4" stopOpacity={1} />
          <stop offset="1.207" stopColor="#63DDFA" stopOpacity={1} />
        </linearGradient>
      </defs>
      <XAxis
        dataKey={xAxisKey}
        tickLine={false}
        dy={10}
        tick={{
          fontSize: '12px',
          color: '#B7B8BA',
          marginRight: '10px',
          lineHeight: '27px',
          fontWeigth: '500',
        }}
      />
      <YAxis
        hide={hideYAxis}
        dy={-5}
        dx={-5}
        tick={{
          fontSize: '12px',
          color: '#B7B8BA',
          marginRight: '10px',
        }}
      />
      <Tooltip
        content={<ChartTooptip isPercentage={isPercentage} isGradient={isGradient} />}
        labelStyle={{ color: '#283238', fontSize: '14px', lineHeight: '27px' }}
        cursor={{ fill: 'white' }}
      />
      <Bar dataKey={barKey} fill={isGradient ? 'url(#premiumGradient)' : barColor} radius={[10, 10, 0, 0]} barSize={50}>
        {renderLabel && <LabelList dataKey={labelKey} position="top" content={renderCustomizedLabel} isPercentage={isPercentage} isGradient={isGradient} />}
      </Bar>
    </BarChart>
  </ResponsiveContainer>
);

export default BriziBarChart;
