import React from 'react';
import classnames from 'classnames';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeart as faHeartSolid } from '@fortawesome/pro-solid-svg-icons';
import {
  faArrowAltCircleDown,
  faHeart,
  faTrash,
  faBan,
} from '@fortawesome/pro-regular-svg-icons';
import {
  Dropdown,
  DropdownButton,
  OverlayTrigger,
  Tooltip,
} from 'react-bootstrap';

import HoverDropdown from '../../../../vendor/libs/hover-dropdown';
import Authorize from '../../../Authorize';
import { removeOrigin } from '../../../../helpers/urls';
import { banHandler, deleteHandler } from '../../actionHelper';
import { toggleFavorite } from '../../actions';
import { PHOTOS_WRITE } from '../../../Authorize/permissions/resources';
import { DELETE_PHOTO, BAN_PHOTO_USER } from '../../permissions';
import { setLargeImageUrl, unsetFullscreenSnapshot } from '../actions';

import style from './style.module.scss';

const Controls = ({
  snapshot,
  toggleFavoriteAction,
  unsetFullscreenSnapshotAction,
  setLargeImageUrlAction,
}) => {
  const {
    _id: snapshotId,
    favorite,
    rawCroppedURI,
    sharedURI,
    hidden,
    userId,
  } = snapshot;

  const banBtnHandler = (userBan) => (evt) => {
    evt.stopPropagation();
    if (userBan) {
      banHandler(!hidden, snapshotId, userId);
    } else {
      banHandler(!hidden, snapshotId);
    }
  };

  const deleteBtnHandler = async () => {
    const isDeleted = await deleteHandler(snapshotId, sharedURI);
    if (isDeleted) {
      unsetFullscreenSnapshotAction();
      setLargeImageUrlAction(null);
    }
  };

  return (
    <div className={style.controls}>
      <OverlayTrigger
        placement="top"
        overlay={<Tooltip className={style.tooltip}>Favorite</Tooltip>}
      >
        <button type="button" onClick={() => toggleFavoriteAction(snapshot)}>
          <FontAwesomeIcon icon={favorite ? faHeartSolid : faHeart} />
        </button>
      </OverlayTrigger>
      <Authorize permissions={BAN_PHOTO_USER}>
        <OverlayTrigger
          placement="top"
          overlay={<Tooltip className={style.tooltip}>{hidden ? 'Unban' : 'Ban'}</Tooltip>}
        >
          <HoverDropdown
            key={hidden}
            onClick={(evt) => evt.stopPropagation()}
            as={DropdownButton}
            variant="default"
            className={classnames('dropdown-toggle-hide-arrow', { active: hidden })}
            title={<FontAwesomeIcon icon={faBan} />}
          >
            <Dropdown.Item onClick={banBtnHandler(false)}>{`${hidden ? 'Unban' : 'Ban'} Photo`}</Dropdown.Item>
            <Dropdown.Divider />
            <Dropdown.Item onClick={banBtnHandler(true)}>{`${hidden ? 'Unban' : 'Ban'} User`}</Dropdown.Item>
          </HoverDropdown>
        </OverlayTrigger>
      </Authorize>

      <OverlayTrigger
        placement="top"
        overlay={<Tooltip className={style.tooltip}>Download</Tooltip>}
      >
        <HoverDropdown
          onClick={(evt) => evt.stopPropagation()}
          as={DropdownButton}
          variant="default"
          className="dropdown-toggle-hide-arrow"
          title={<FontAwesomeIcon icon={faArrowAltCircleDown} />}
        >
          <Dropdown.Item href={removeOrigin(rawCroppedURI)} download>Raw</Dropdown.Item>
          <Dropdown.Divider />
          {sharedURI && (
            <Dropdown.Item href={removeOrigin(sharedURI)} download>
              Overlayed
            </Dropdown.Item>
          )}
        </HoverDropdown>
      </OverlayTrigger>

      <Authorize or permissions={[PHOTOS_WRITE, DELETE_PHOTO]}>
        <OverlayTrigger
          placement="top"
          overlay={<Tooltip className={style.tooltip}>Delete</Tooltip>}
        >
          <button type="button" onClick={deleteBtnHandler}>
            <FontAwesomeIcon icon={faTrash} />
          </button>
        </OverlayTrigger>

      </Authorize>
    </div>
  );
};


export default connect(
  ({
    lightbox: {
      snapshot: {
        data: snapshot,
      },
    },
  }) => ({ snapshot }),
  {
    toggleFavoriteAction: toggleFavorite,
    unsetFullscreenSnapshotAction: unsetFullscreenSnapshot,
    setLargeImageUrlAction: setLargeImageUrl,
  },
)(Controls);
