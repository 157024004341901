import React, { useState } from 'react';
import * as Recharts from 'recharts';
import { connect } from 'react-redux';
import { isEmpty, zipWith } from 'lodash';

import ChartCard from '../../ChartComponents/ChartCard';
import ChartTooltip from '../../ChartComponents/ChartTooltip';
import Info from '../../../../components/Info';

import { formatDateToTimezone } from '../../../../helpers/utils';

const initialChartLine = {
  angry: true,
  calm: true,
  confused: true,
  disgusted: true,
  fear: true,
  happy: true,
  sad: true,
  surprised: true,
};

const EmotionTimeline = ({ emotions, timezone }) => {
  const [chartLines, setChartLines] = useState(initialChartLine);

  const {
    angry,
    calm,
    confused,
    disgusted,
    fear,
    happy,
    sad,
    surprised,
  } = emotions;

  const handleLegendClick = (series) => {
    setChartLines({ ...chartLines, [series]: !chartLines[series] });
  };

  const chartData = zipWith(angry, calm, confused, disgusted, fear, happy, sad, surprised, (
    angryEntry,
    calmEntry,
    confusedEntry,
    disgustedEntry,
    fearEntry,
    happyEntry,
    sadEntry,
    surprisedEntry,
  ) => ({
    angry: angryEntry.y,
    calm: calmEntry.y,
    confused: confusedEntry.y,
    disgusted: disgustedEntry.y,
    fear: fearEntry.y,
    happy: happyEntry.y,
    sad: sadEntry.y,
    surprised: surprisedEntry.y,
    time: formatDateToTimezone(angryEntry.x, timezone, 'hh:mm a'),
  }));

  if (!isEmpty(chartData)) {
    return (
      <ChartCard
        isGradient
        title={(
          <Info
            label="Emotions Timeline"
            content="A visualization of the emotions of users in Brizicam photos over the event"
            className="ml-2"
          />
        )}
      >
        <Recharts.ResponsiveContainer width="100%" height={350}>
          <Recharts.LineChart data={chartData} isAnimationActive={false}>
            <Recharts.XAxis
              dataKey="time"
              tickLine={false}
              dy={10}
              dx={5}
              tick={{
                fontSize: '12px',
                color: '#B7B8BA',
                marginRight: '10px',
                lineHeight: '27px',
                fontWeigth: '500',
              }}
            />
            <Recharts.YAxis
              tickLine={false}
              dy={-5}
              dx={-5}
              tick={{
                fontSize: '12px',
                color: '#B7B8BA',
                marginRight: '10px',
              }}
            />
            <Recharts.Tooltip content={<ChartTooltip />} />
            <Recharts.Legend
              verticalAlign="top"
              align="right"
              wrapperStyle={{ fontSize: '14px', color: '#4E5155', textTransform: 'capitalize' }}
              iconType="circle"
              iconSize="11"
              onClick={({ dataKey }) => handleLegendClick(dataKey)}
            />
            <Recharts.Line type="monotone" dataKey="angry" stroke="#979A9A" fill="#979A9A" dot={false} strokeWidth={2} hide={!chartLines.angry} />
            <Recharts.Line type="monotone" dataKey="calm" stroke="#F39C12" fill="#F39C12" dot={false} strokeWidth={2} hide={!chartLines.calm} />
            <Recharts.Line type="monotone" dataKey="confused" stroke="#9A7D0A" fill="#9A7D0A" dot={false} strokeWidth={2} hide={!chartLines.confused} />
            <Recharts.Line type="monotone" dataKey="disgusted" stroke="#117864" fill="#117864" dot={false} strokeWidth={2} hide={!chartLines.disgusted} />
            <Recharts.Line type="monotone" dataKey="fear" stroke="#633974" fill="#633974" dot={false} strokeWidth={2} hide={!chartLines.fear} />
            <Recharts.Line type="monotone" dataKey="happy" stroke="#85C94C" fill="#85C94C" dot={false} strokeWidth={2} hide={!chartLines.happy} />
            <Recharts.Line type="monotone" dataKey="sad" stroke="#1A5276" fill="#1A5276" dot={false} strokeWidth={2} hide={!chartLines.sad} />
            <Recharts.Line type="monotone" dataKey="surprised" stroke="#C0392B" fill="#C0392B" dot={false} strokeWidth={2} hide={!chartLines.surprised} />
          </Recharts.LineChart>
        </Recharts.ResponsiveContainer>
      </ChartCard>
    );
  }

  return null;
};

export default connect(
  ({
    events: { currentEvent: { timezone } },
    analytics: { briziVision: { data: { emotions } } },
  }) => ({
    timezone,
    emotions,
  }),
)(EmotionTimeline);
