import React from 'react';
import classnames from 'classnames';
import { connect } from 'react-redux';
import { isEmpty } from 'lodash';
import { Row, Col } from 'react-bootstrap';

import Loader from '../../../shared/Loader';
import AgeChart from './AgeChart';
import GenderChart from './GenderChart';
import EmotionsChart from './EmotionsChart';
import TraitsChart from './TraitsChart';
import EmotionsTimelineChart from './EmotionsTimelineChart';
import PremiumFallback from '../ChartComponents/PremiumFallback';

import { can } from '../../../components/Authorize';
import { ANALYTICS_VISION_READ } from '../permissions';

import style from './style.module.scss';

const VisionSection = ({ data, error, isFetching }) => {
  if (isFetching) {
    return <Loader />;
  }

  if (error) {
    return (
      <div>
        Error querying Vision Data for the selected event
      </div>
    );
  }

  if (!isFetching) {
    return (
      <div className="print-break-page print-brizi-vision">
        <div className="mt-4">
          <div className={style.visionHeader}>
            <h4>Brizi Vision Data</h4>
            <div className={style.premiumContainer}>
              <span className={classnames(style.premiumLabel, 'hide-on-print')}>
                PREMIUM
              </span>
            </div>
          </div>
          {!can(ANALYTICS_VISION_READ) && <PremiumFallback />}
          {can(ANALYTICS_VISION_READ) && !isEmpty(data) && (
            <>
              <Row className="mt-4">
                <Col md="9">
                  <AgeChart />
                </Col>
                <Col md="3">
                  <GenderChart />
                </Col>
              </Row>
              <Row className="mt-4">
                <Col>
                  <EmotionsTimelineChart />
                </Col>
              </Row>
              <Row className="mt-4">
                <Col md="7">
                  <EmotionsChart />
                </Col>
                <Col md="5">
                  <TraitsChart />
                </Col>
              </Row>
            </>
          )}
        </div>
      </div>
    );
  }

  return null;
};

export default connect(
  ({ analytics: { briziVision: { isFetching, data, error } } }) => ({ isFetching, data, error }),
)(VisionSection);
