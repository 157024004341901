import React from 'react';
import classnames from 'classnames';
import { connect } from 'react-redux';
import ChartCard from '../../ChartComponents/ChartCard';
import Info from '../../../../components/Info';
import OverlayContainer from './OverlayContainer';

import style from './style.module.scss';

const OverlayChart = ({ overlays, overlayUsage }) => {
  if (overlayUsage.length) {
    return (
      <ChartCard title={(
        <Info
          label="Overlays Usage"
          content="Overlay selection by users"
          className="ml-2"
        />
      )}
      >
        <div
          className={classnames(style.overlayListWrapper, {
            [style.flexWrap]: overlayUsage.length > 5,
          })}
        >
          {overlayUsage.map(({ overlay, percentage }, index) => (
            <OverlayContainer
              overlay={overlays[overlay]}
              percentage={percentage}
              index={index}
            />
          ))}
        </div>
      </ChartCard>
    );
  }

  return null;
};

export default connect(
  ({
    overlays,
    analytics: {
      basic: {
        data: { overlayUsage },
      },
    },
  }) => ({
    overlays,
    overlayUsage,
  }),
)(OverlayChart);
