import React, { useEffect } from 'react';
import classnames from 'classnames';
import { connect } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import {
  Button,
  OverlayTrigger,
  Popover,
  Spinner,
} from 'react-bootstrap';

import { usePage } from '../../helpers/hooks';

import EventSelectorButton from '../../components/EventSelector/Button';
import EventSelector from '../../components/EventSelector/Modal';
import BasicSection from './BasicSection';
import VisionSection from './VisionSection';
import NoDataFallback from './ChartComponents/NoDataFallback';

import { fetchAnalyticsData } from './actions';
import { buildURL } from '../../helpers/urls';

import style from './style.module.scss';

const Analytics = ({
  event,
  hasValues,
  isFetching,
  selectedEventsPage,
  fetchAnalyticsDataAction,
}) => {
  const mainPageKey = usePage();
  const { eventId } = useParams();
  const history = useHistory();
  const isLoading = isFetching || (event && event._id !== eventId);

  useEffect(() => {
    if (event && selectedEventsPage === mainPageKey && event._id === eventId) {
      fetchAnalyticsDataAction(event.slugDate);
    }
  }, [event, eventId, selectedEventsPage]);

  useEffect(() => {
    if (event && (!eventId || event._id !== eventId)) {
      history.replace(`/analytics/${event._id}`);
    }
  }, [event, eventId]);

  return (
    <div className="d-flex flex-column flex-grow-1">
      <div className="pageHeader">
        <h4>Analytics Page</h4>
        <EventSelectorButton showFutureEvents />
        {event && event.eventStatus !== 'live' && (
          <OverlayTrigger
            placement="top"
            overlay={(
              <Popover id="exportData">
                <Popover.Content>
                  Generate an excel (.csv) file with  the raw data from this page
                </Popover.Content>
              </Popover>
            )}
          >
            <Button
              className="small"
              target="__blank"
              variant="primary"
              href={buildURL('analytics', event.slugDate, 'export')}
            >
              Export Data
            </Button>
          </OverlayTrigger>
        )}
      </div>
      <div className={classnames(style.content, 'display-block-on-print')}>
        {isLoading && (
          <div className="d-flex flex-grow-1 align-items-center justify-content-center">
            <Spinner as="span" animation="border" aria-hidden="true" />
          </div>
        )}
        {!isLoading && hasValues && (
          <>
            <BasicSection />
            <VisionSection />
          </>
        )}
        {!isLoading && hasValues === false && <NoDataFallback />}
      </div>
      <EventSelector singleSelection showTemplate />
    </div>
  );
};

export default connect(
  ({
    events: { selectedEvents, selectedEventsPage },
    analytics: { basic: { hasValues, isFetching } },
  }) => ({
    event: selectedEvents[0],
    selectedEventsPage,
    hasValues,
    isFetching,
  }),
  { fetchAnalyticsDataAction: fetchAnalyticsData },
)(Analytics);
